import React, { Component, lazy, useContext, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
// import { Route, Router, useLocationProperty,navigate  } from 'wouter';
import { Loader } from "rsuite";
import logo from "./logo.svg";
import "./App.css";
import "./styles/Nrjwtr.css";
import { NrjRequire } from "./utilities/NrjRequire";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { NrjLgn } from './components/NrjLgn';

const LzNrjLnkPg = React.lazy(() => import("./components/admn/NrjLnkpg"));
const LzNrjLgn = React.lazy(() => import("./components/NrjLgn"));
const LzNrjWstCmp = React.lazy(() => import("./components/admn/NrjWstCmp"));
const LzNrjWstTdy = React.lazy(() => import("./components/admn/NrjWstTdy"));
const LzNrjWstHcf = React.lazy(() => import("./components/wstinc/NrjWstHcf"));
const LzNrjIncinrt = React.lazy(() => import("./components/wstinc/NrjIncinrt"));
const LzNrjPrgNts = React.lazy(()=> import("./components/admn/NrjPrgNts"))
const LzNrjWstCld = React.lazy(()=> import("./components/admn/NrjWstCltRpt"));
const LzNrjSlDts = React.lazy(()=>import("./components/pharma/NrjSlDts"))
const LzNrjEqpSlctr = React.lazy(()=>import("./components/wstinc/NrjEqpSlct"))
const LzNrjWhlPur = React.lazy(()=> import("./components/pharma/NrjWhlPur"))
const LzNrjIncinr = React.lazy(() => import("./components/wstinc/NrjIncinrt"));
const LzNrjPhrmPurSl = React.lazy(()=>import ("./components/pharma/NrjPhrmPurSl"))
const LzNrjRadLst = React.lazy(()=>import ("./components/viewbox/NrjRadLst"))
const LzNrjPrgDts = React.lazy(()=>import("./components/admn/NrjPrgdts"))
const LzNrjPrgLst = React.lazy(()=>import("./components/admn/NrjPrgNLst"))

const App = () => {
  // const hashLocation = () => window.location.hash.replace(/^#/, "") || "/";
  // const hashNavigate = (to:any) => navigate('#' + to);
  // const useHashLocation = () => {
  //   const location = useLocationProperty(hashLocation);
  //   return [location, hashNavigate];
  // };
  // const [textDts, setTextDts] = useState("");

  // const onChangeDts = (data:string)=>{
  //   let dta : string = textDts;
  //   let fldN : any = utilities(2,data, "");
  //   if (textDts){
  //     dta = textDts + "=";
  //     let d : any =  utilities(1,dta,fldN);
  //     if (d){
  //       dta = d;
  //     }

  //   }
  //   dta += data;

  //   setTextDts(dta);

  // }

  useEffect(() => {
    document.title = "Atishay Pvt Ltd";
    sessionStorage.setItem("cmpid", "104506")
    sessionStorage.setItem("usrid", "101")
  });
  const queryClient = new QueryClient();
  return (
    <div>
      <BrowserRouter>
        
          <Routes>
            <Route
              path="/"
              element={
                <React.Suspense fallback={<Loader content="Medium" />}>
                  {/* <LzNrjLgn /> */}
                  <LzNrjIncinr />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/LinkDts"
              element={
                <React.Suspense fallback={<Loader content="Medium" />}>
                  <NrjRequire>
                    <LzNrjLnkPg />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/icncr"
              element={
                <React.Suspense fallback={<Loader center size="lg" />}>
                  <NrjRequire>
                    <LzNrjWstCmp />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/icntdy"
              element={
                <React.Suspense fallback={<Loader content="Medium" />}>
                  <NrjRequire>
                    <LzNrjWstTdy />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/icncr"
              element={
                <React.Suspense fallback={<Loader center size="lg" />}>
                  <NrjRequire>
                    <LzNrjWstCmp />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/indpcw"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjWstTdy />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/hcf"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjWstHcf />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/incnr"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjIncinrt />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/prgnts"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjPrgNts />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/prgntslst"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjPrgLst />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/prgdts"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjPrgDts />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/wstcltd"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjWstCld />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            {/* <Route
              path="/vhcl"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjIncinrtr />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route> */}
            <Route
              path="/phrmsl"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjSlDts />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            {/* <Route
              path="/atclv"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjAutclv />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route> */}
            {/* <Route
              path="/shrdr"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjShrdr />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route> */}
            <Route
              path="/eqp"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjEqpSlctr />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/whpur"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjWhlPur />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/whpursl"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjPhrmPurSl />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/vbx"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjRadLst />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
          </Routes>
        {/* </QueryClientProvider>  */}
      </BrowserRouter>
    </div>
  );
};
export default App;